// Section: Variables
$shadow-normal: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
$shadow-medium: 0 4px 6px -1px rgba(114, 114, 114, 0.1),
  0 2px 4px -1px rgba(114, 114, 114, 0.06);
$shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
  0 4px 6px -2px rgba(0, 0, 0, 0.05);

@-webkit-keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@keyframes slideLeft {
  0% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }

  100% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }
}

@-webkit-keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

@keyframes slideRight {
  0% {
    opacity: 1;
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
  }

  100% {
    opacity: 0;
    -webkit-transform: translateX(100%);
    transform: translateX(100%);
  }
}

// Section: Header
.header {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  height: auto;
  padding: 0.8rem 0;
  margin: 0 auto;
  z-index: 999;
  border: none;
  outline: none;
  background: rgba($c-main, 0);
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;

  // box-shadow: $shadow-medium;

  @media (max-height: 768px) {
    padding: 0.8rem 0;
  }

  .brand {
    .logo-2 {
      display: none;
    }

    img {
      height: 42px;
      width: 100%;

      @media (max-width: 800px) {
        height: 36px;
      }

      @media (max-height: 768px) {
        height: 36px;
      }

      @media (max-width: 500px) {
        height: 30px !important;
      }

      @media (max-width: 380px) {
        height: 30px !important;
      }
    }
  }

  ul {
    margin-bottom: 0;
    padding-left: 1rem;
    padding-right: 1rem;

    @media (max-width: 1199px) {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .call--to--action {
    margin: 0 1rem;
    @media (max-width: 767px) {
      display: none;
    }
  }

  .action--link {
    display: none !important;
    @media (max-width: 767px) {
      display: block !important;
    }
  }

  .wrapper {
    display: flex;
    // flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;

    @media only screen and (max-width: 1199px) {
      flex-wrap: wrap;
    }
  }

  &-item-left {
    display: flex;
    flex: 0 0 14%;
  }

  &-item-center {
    display: flex;
    flex: 0 0 66%;
    justify-content: center;
  }

  &-item-right {
    display: flex;
    flex: 0 0 20%;
    align-items: center;
    justify-content: flex-end;

    @media (max-width: 800px) {
      flex-wrap: wrap;
    }

    .lang--area {
      display: flex;
      align-items: center;
      gap: 8px;
      @media (max-width: 306px) {
        gap: 0px;
      }

      img {
        height: 16px;
        width: 28px;
      }

      span {
        text-transform: uppercase;
        font-size: 16px;
        height: 32px;
        width: 32px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
      }

      #activeLang {
        opacity: 1;
        // background-color: $c-second;
        color: $c-white;
        font-weight: 800;
      }
      #inactive {
        opacity: 0.8;
        // background-color: rgba($c-main, 1);
        color: $c-white;
      }
    }

    .menu-icon {
      // font-size: 1.35rem;
      // display: flex;
      // align-items: center;
      // justify-content: center;
      // gap: 1rem;
      // margin-left: 1rem;
      // border: none;
      // outline: none;
      // color: $c-main;
      // transition: all 0.3s ease;
      font-size: 1rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
      width: 40px;
      height: 40px;
      background-color: rgba($c-main-dark, $alpha: 0.1);
      border-radius: 6px;
      -ms-flex-align: center;
      align-items: center;
      // color: $c-second;
      -webkit-box-pack: center;
      -ms-flex-pack: center;
      justify-content: center;
      gap: 0.5rem;
      margin-left: 0.3rem;
      border: none;
      text-decoration: none;
      outline: none;
      color: $c-main-dark;
      -webkit-transition: all 0.3s ease;
      -o-transition: all 0.3s ease;
      transition: all 0.3s ease;
    }

    @media (max-width: 1399px) {
      .button-3 a {
        padding: 0 20px !important;
      }
    }

    @media (max-width: 580px) {
      .button-3 {
        padding: 0 !important;
        height: 40px;

        a {
          font-size: 12px;
          padding: 0 16px !important;
        }
      }
    }
  }

  a,
  button {
    font-family: "Roboto", sans-serif;
    font-size: inherit;
    line-height: inherit;
    cursor: pointer;
    border: none;
    outline: none;
    background: 0 0;
    text-decoration: none;
  }

  .menu {
    width: 100%;

    .menu-section {
      display: flex;
      justify-content: space-evenly;
      align-items: center;

      @media (max-width: 1199px) {
        display: block;
      }
    }
  }

  .menu > ul > li {
    display: inline-block;
    line-height: 3.125rem;
    margin-left: 1.4rem;
    border-bottom: 2px solid transparent;

    @media (max-width: 1399px) {
      margin-left: 0.6rem;
    }

    @media (max-width: 1199px) {
      text-align: start;
    }

    > a {
      position: relative;
      font-family: inherit;
      font-size: 16px;
      font-weight: 600;
      line-height: 1.25;
      border: none;
      text-decoration: none !important;
      outline: none !important;
      color: $c-white !important;
      // text-transform: capitalize;
      text-rendering: optimizeLegibility;
      transition: color 0.3s ease-in-out;

      @media (max-width: 1199px) {
        color: $c-dark !important;
      }

      &:after {
        content: "";
        display: block;
        margin: 0 auto;
        position: absolute;
        width: 0;
        left: 0;
        right: 0;
        height: 4px;
        background-color: $c-white;
        -webkit-transition: opacity 0.3s ease, width 0.3s ease;
        -moz-transition: opacity 0.3s ease, width 0.3s ease;
        -o-transition: opacity 0.3s ease, width 0.3s ease;
        transition: opacity 0.3s ease, width 0.3s ease;
        -ms-transition: opacity 0.3s ease, width 0.3s ease;
        border-radius: 10px;

        @media (max-width: 1199px) {
          width: 6px;
          height: 0;
          margin: 0 0;
          position: absolute;
          left: 0;
          top: 0;
        }
      }

      &.active,
      &:hover {
        font-weight: 600;
        color: $c-white;

        @media (max-width: 1199px) {
          color: $c-second !important;
        }

        &:after {
          width: 100%;

          @media (max-width: 1199px) {
            width: 6px;
            height: 100%;
            color: $c-main;
            background-color: $c-second;
          }
        }
      }

      @media (max-width: 1199px) {
        color: $c-dark;
      }
    }

    .menu-subs {
      position: absolute;
      width: 100%;
      height: auto;
      margin-top: 1.75rem;
      padding: 1rem 2rem;
      border: none;
      outline: none;
      z-index: 500;
      opacity: 0;
      visibility: hidden;
      border-radius: 0.25rem;
      border-top: 3px solid $c-second;
      background: $c-white;
      box-shadow: $shadow-medium;
      transition: all 0.5s ease-in-out;

      > ul > li {
        line-height: 1;

        > a {
          display: inline-block;
          font-family: inherit;
          font-size: 1rem;
          font-weight: 500;
          line-height: 1.25;
          padding: 0.75rem 0;
          border: none;
          outline: none;
          color: $c-main;
          text-transform: capitalize;
          text-rendering: optimizeLegibility;
          transition: all 0.3s ease-in-out;
        }
      }

      &.menu-mega {
        left: 50%;
        transform: translateX(-50%);

        > .list-item > ul > li {
          display: block;
          line-height: 1;

          > a {
            display: inline-block;
            font-family: inherit;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.25;
            padding: 0.5rem 0;
            border: none;
            outline: none;
            color: $c-main;
            text-transform: capitalize;
            text-rendering: optimizeLegibility;
            transition: color 0.3s ease-in-out;
          }
        }
      }

      &.menu-column-4 {
        display: flex;
        flex-wrap: wrap;
        max-width: 68rem;
        width: 100%;
        padding: 1.25rem 1rem;

        > .list-item {
          flex: 0 0 50%;
          padding: 0 1rem;

          .title {
            font-family: inherit;
            font-size: 1rem;
            font-weight: 500;
            line-height: 1;
            padding: 0.75rem 0;
            border: none;
            outline: none;
            color: $c-second;
            text-transform: uppercase;
            text-rendering: optimizeLegibility;
            transition: all 0.3s ease-in-out;
          }

          &.text-center .title {
            text-align: center;
          }

          img.responsive {
            max-width: 100%;
            width: 100%;
            height: auto;
            margin-top: 0.75rem;
            object-fit: cover;
            vertical-align: middle;
          }
        }
      }

      &.menu-mega > .list-item > ul > li > a:hover,
      > ul > li > a:hover {
        color: $c-white;
      }
    }

    .menu-column-1 {
      min-width: 16rem;
      max-width: 20rem;
    }
  }

  &-item-right a:hover,
  .menu > ul > li:hover > a {
    color: $c-white;
  }
}

// Section: Media Query
@media only screen and (min-width: 993px) {
  .header .menu > ul > li.menu-item-has-children:hover .menu-subs {
    margin-top: 0.5rem;
    opacity: 1;
    visibility: visible;
  }
}

.menu-mobile-header,
.menu-mobile-trigger {
  display: none;
}

// Section: Media Query
@media only screen and (max-width: 1199px) {
  .wrapper {
    justify-content: space-between;
  }

  .header {
    &-item-center {
      flex: 0 0 100%;
      order: 3;
    }

    &-item-left,
    &-item-right {
      flex: 0 0 auto;
    }

    .menu-mobile-trigger {
      position: relative;
      display: block;
      cursor: pointer;
      width: 1.75rem;
      height: 1rem;
      border: none;
      outline: none;
      // margin: 0 1.25rem;
      margin: 0;
      background: none;
      transform: rotate(0deg);
      transition: 0.35s ease-in-out;

      span {
        display: block;
        position: absolute;
        width: 100%;
        height: 2px;
        left: 0;
        border: none;
        outline: none;
        opacity: 1;
        border-radius: 0.25rem;
        background: $c-white;
        transform: rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-child(1) {
          top: 0;
        }

        &:nth-child(2),
        &:nth-child(3) {
          top: 0.5rem;
        }

        &:nth-child(4) {
          top: 1rem;
        }
      }
    }

    &-item-right {
      align-items: center;
    }

    .menu {
      position: fixed;
      top: 0;
      left: 0;
      width: 20rem;
      height: 100%;
      z-index: 1099;
      overflow: hidden;
      background: $c-white;
      transform: translate(-100%);
      transition: all 0.5s ease-in-out;

      &.active {
        transform: translate(0%);
      }

      > ul > li {
        display: block;
        line-height: 1;
        margin: 0;

        > a {
          display: block;
          line-height: 3.125rem;
          height: 3.125rem;
          padding: 0 3.125rem 0 1rem;
          border-bottom: 1px solid rgba(0, 0, 0, 0.1);

          i.ion {
            position: absolute;
            font-size: 1.25rem;
            line-height: 3.125rem;
            top: 0;
            right: 0;
            width: 3.125rem;
            height: 3.125rem;
            text-align: center;
            transform: rotate(-90deg);
          }
        }
      }

      .menu-mobile-header {
        position: relative;
        position: sticky;
        display: flex;
        align-items: center;
        justify-content: space-between;
        top: 0;
        height: 3.125rem;
        z-index: 501;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        background: $c-white;

        .menu-mobile-arrow {
          display: none;
          font-size: 1.25rem;
          line-height: 3.125rem;
          width: 3.125rem;
          height: 3.125rem;
          cursor: pointer;
          border-right: 1px solid rgba(0, 0, 0, 0.1);
          color: $c-main;
          text-align: center;
        }

        &.active .menu-mobile-arrow {
          display: block;
        }

        .menu-mobile-title {
          font-family: inherit;
          font-size: 1rem;
          font-weight: 500;
          line-height: inherit;
          color: $c-main;
          text-transform: capitalize;
          text-rendering: optimizeLegibility;
        }

        .menu-mobile-close {
          font-size: 2.25rem;
          line-height: 3.125rem;
          cursor: pointer;
          width: 3.125rem;
          height: 3.125rem;
          border-left: 1px solid rgba(0, 0, 0, 0.1);
          color: $c-main;
          text-align: center;
        }
      }

      .menu-section {
        height: 100%;
        overflow-y: auto;
        overflow-x: hidden;
      }

      > ul > li .menu-subs {
        position: absolute;
        display: none;
        top: 0;
        left: 0;
        max-width: none;
        min-width: auto;
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 1rem;
        padding-top: 4rem;
        opacity: 1;
        overflow-y: auto;
        visibility: visible;
        transform: translateX(0%);
        box-shadow: none;

        &.menu-mega {
          position: absolute;
          display: none;
          top: 0;
          left: 0;
          max-width: none;
          min-width: auto;
          width: 100%;
          height: 100%;
          margin: 0;
          padding: 1rem;
          padding-top: 4rem;
          opacity: 1;
          overflow-y: auto;
          visibility: visible;
          transform: translateX(0%);
          box-shadow: none;
        }

        &.active {
          display: block;
        }

        &.menu-column-4 > .list-item {
          flex: 0 0 100%;
          padding: 0rem;

          img.responsive {
            margin-top: 0;
          }

          &.text-center {
            .title {
              margin-bottom: 1.25rem;
            }

            &:last-child .title {
              margin-bottom: 0rem;
            }
          }
        }

        > ul > li > a {
          display: block;
        }

        &.menu-mega > .list-item > ul {
          > li > a {
            display: block;
          }

          margin-bottom: 1rem;
        }
      }
    }
  }

  .overlay--menu {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1098;
    opacity: 0;
    visibility: hidden;
    background: rgba(0, 0, 0, 0.55);
    transition: all 0.5s ease-in-out;

    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
}

.mobile--bottom {
  display: none !important;

  @media (max-width: 800px) {
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    padding: 16px 12px;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0 auto;
  }

  &--lang {
    img {
      width: 40px;
    }
  }
}

.sticky {
  // background-color: $c-main;
  background-color: $c-white;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  position: sticky;
  z-index: 9999;
  display: block;
  top: 0;
  left: 0;
  width: 100%;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  transition: all 0.3s ease;

  .brand {
    .logo-1 {
      display: none !important;
    }

    .logo-2 {
      display: block !important;
      width: 100%;
    }
  }

  .menu-mobile-trigger span {
    background-color: $c-main-dark !important;
  }

  .button {
    background-color: rgba($c-main, 1);
    color: $c-white;

    a {
      color: $c-white;
    }
  }

  ul.menu-section {
    li {
      a {
        color: $c-main-dark !important;

        &.active,
        &:hover {
          color: $c-main !important;
        }

        &:after {
          background-color: $c-second;
        }
      }
    }
  }

  .lang--area {
    #activeLang {
      opacity: 1;
      // background-color: $c-second;
      color: $c-main;
      font-weight: 800;
    }
    #inactive {
      opacity: 0.8;
      // background-color: rgba($c-main, 1);
      color: $c-main;
    }
  }
}

// Float Lang

.floating--lang {
  position: fixed;
  bottom: 24px;
  right: 24px;
  width: 60px;
  height: 60px;
  background: #fff;
  border-radius: 50%;
  z-index: 1;
  cursor: pointer;
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.1);
  span {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: 0.3s ease-in-out;

    img {
      height: 32px;
      width: 32px;
    }
  }

  &.active span {
    transform: rotate(180deg);
  }

  ul {
    position: absolute;
    bottom: 2%;
    background: #fff;
    min-width: fit-content;
    right: 0;
    padding: 8px;
    border-radius: 8px;
    opacity: 0;
    visibility: hidden;
    transition: 0.3s;
  }

  &.active ul {
    bottom: 65px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
    opacity: 1;
    visibility: visible;
    transition: 0.3s;
  }

  ul li {
    list-style: none;
    text-decoration: none;

    a {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 8px;
      background-color: $c-white;
      border-radius: 4px;
      margin-bottom: 4px;
      color: $c-dark2;

      &:hover {
        color: $c-second;
      }

      img {
        margin-right: 15px;
        opacity: 1;
        // transform: scale(.7);
        height: 24px;
        width: 32px;
      }

      p {
        margin-bottom: 0;
      }
    }

    #active {
      background-color: rgba($c-second, 0.2) !important;
      color: $c-second !important;
      opacity: 1;
    }

    #inactive {
      background-color: $c-white !important;
      opacity: 1;
    }
  }
}
