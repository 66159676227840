.button {
  display: inline-flex;
  height: 44px;
  padding: 0 24px;
  text-transform: none;
  text-decoration: none;
  font-size: 0.8em;
  font-weight: 500;
  letter-spacing: 1.5px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  border-radius: 2px;
  text-align: center;
  cursor: pointer;

  @media (max-width: 800px) {
    padding: 0 20px;
    height: 40px;
  }
  @media (max-width: 1399px) {
    padding: 0 16px;
    height: 40px;
  }
}

a {
  text-decoration: none;
  letter-spacing: 1px;
}





/* Second Button */

.button-1 {
  position: relative;
  overflow: hidden;
  padding: 0 !important;
  height: 56px;
  background-color: $c-second;
  cursor: pointer;
  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-white;
    padding: 0 32px;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

    button {
      position: relative;
      transition: all 0.45s ease-Out;
      color: $c-white;
      font-size: 16px;
      text-transform: none;
      font-weight: 600;
      cursor: pointer;
      padding: 0 10px;
      background-color: transparent;
      border: none;
  
    }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-main;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a,&:hover button {
    color: $c-white;
  }
}

.button-2 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 60px;

  background-color: $c-white;

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-second;
    font-size: 16px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
    padding: 0 16px;

  }

  button {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-second;
    font-size: 16px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
    padding: 0 10px;
    background-color: transparent;
    border: none;

  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-second;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }


  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a,  &:hover button {
    color: $c-white;
  }
}

.button-3 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 44px;
  background-color: $c-white;
  padding: 0 !important;

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-second;
    padding: 0 24px !important;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    @media (max-width: 400px) {
      padding: 0 16px !important;
    }
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-second;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
    color: $c-white;
  }
}

.button-transparent {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 40px;
  background-color: rgba($c-main, 0.04);

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-main;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-main;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
    color: $c-white;
  }
}

.button-4 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 44px;
  background-color: #fef4f7;

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-second !important;
    font-size: 18px;
    text-transform: none;
    font-weight: 700;
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-second;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
    color: $c-white !important;
  }
}

.button-5 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 44px;
  background-color: rgba($c-third, 0.04);

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-third;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-third;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
    color: $c-white;
  }
}

.button--whatsapp {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 44px;
  background-color: rgba(30, 206, 30, 0.08);
  animation: shadow-pulse 1s infinite;

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: rgb(0, 133, 11);
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-second;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
    color: $c-white;
  }
}

.button--whatsapp--2 {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 44px;
  background-color: rgb(24, 184, 24);
  animation: shadow-pulse 1s infinite;

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: rgb(255, 255, 255);
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
    cursor: pointer;
  }

  #circle {
    width: 0%;
    height: 0%;
    opacity: 0;
    line-height: 40px;
    border-radius: 50%;
    background: $c-second;
    position: absolute;
    transition: all 0.5s ease-Out;
    top: 20px;
    left: 90px;
    cursor: pointer;
  }

  &:hover #circle {
    width: 200%;
    height: 500%;
    opacity: 1;
    top: -70px;
    left: -70px;
  }

  &:hover a {
    color: $c-white;
  }
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(30, 206, 30, 0.2);
  }
  100% {
    box-shadow: 0 0 0 35px rgba(30, 206, 30, 0);
  }
}

@keyframes shadow-pulse-big {
  0% {
    box-shadow: 0 0 0 0px rgba(30, 206, 30, 0.1);
  }
  100% {
    box-shadow: 0 0 0 70px rgba(30, 206, 30, 0);
  }
}

.button-link {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 44px;
  background-color: transparent;
  padding: 0 12px;

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-main;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
  }

  //   #circle {
  // 	width: 0%;
  // 	height: 0%;
  // 	opacity: 0;
  // 	line-height: 40px;
  // 	border-radius: 50%;
  // 	background: $c-third;
  // 	position: absolute;
  // 	transition: all .5s ease-Out;
  // 	top: 20px;
  // 	left: 90px;
  // 	cursor: pointer;
  //   }

  //   &:hover #circle {
  // 	width: 200%;
  // 	height: 500%;
  // 	opacity: 1;
  // 	top: -70px;
  // 	left: -70px;
  //   }

  &:hover a {
    color: $c-second;
  }
}

.button-link-w {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  height: 44px;
  background-color: transparent;
  padding: 0 0;

  a {
    position: relative;
    transition: all 0.45s ease-Out;
    color: $c-white;
    font-size: 14px;
    text-transform: none;
    font-weight: 600;
  }


  &:hover a {
    color: $c-second;
  }
}
