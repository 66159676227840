






.blog--heading--s1 {
  color: $c-dark-2;
  --minFontSize: 18px;
  --maxFontSize: 24px;
  --scaler: 7vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  position: relative;
  word-spacing: 0;
  font-weight: 600;
  text-align: left;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    bottom: -15px;
    height: 5px;
    border-radius: 50px;
    width: 40px;
    background-color: $c-second;

    @media (max-width: 1366px) {
      width: 30px;
    }
  }

  &:after {
    content: "";
    position: absolute;
    left: 0;
    bottom: -13px;
    height: 1px;
    border-radius: 50px;
    width: 100%;
    max-width: 100%;
    background-color: $c-second;
  }
}





.categories--area {
  background-color: $c-gray-l2;
  padding: 12px;
  border-radius: 8px;

  &--item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-decoration: none;
    margin-bottom: 8px;
    // box-shadow: rgba(149, 157, 165, 0.1) 0px 4px 16px;
    padding: 6px 8px;
    border-radius: 8px;
    background-color: white;

    &--left {

      img {
        width: 32px;
        height: 32px;
        padding: 4px;
        margin-right: 12px;
      }

      span {
        font-size: 14px;
        font-weight: 500;
        color: $c-dark;

        small {
          color: $c-gray;
        }
      }

    }

    &--right {

      svg {
        color: $c-gray;
      }
    }
  }
}




.pagination--custom {
  padding-top: 24px;

  ul {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 0;
  }
}



.blog--area {
  
  &--head {
    img {
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      width: 100%;
      object-fit: cover;
      min-height: 440px;
      height: auto;
      border-radius: 4px;
    }
  }

  .media {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    column-gap: 8px;
    padding-bottom: 32px;

    &--item {
      // height: 400px;
      width: 48%;
      img {
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 4px;
      }
    }
  }

  &--body {
    &--title {
      font-size: clamp(16px, 3vw, 24px);
      color: $c-dark;
      font-weight: 600;
    }

    &--categories {

      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;

      a {
        text-decoration: none;
        color: $c-main !important;
        background-color: rgba($c-main, .1);
        padding: 6px 10px;
        border-radius: 6px;
        margin-right: 8px;
      }
    }

    &--tags {

      display: flex;
      align-items: center;
      justify-content: flex-start;
      flex-wrap: wrap;
      padding-bottom: 16px;

      h5 {
        margin-bottom: 0;
        margin-right: 12px;
        font-size: 16px;
      }

      a {
        text-decoration: none;
        color: $c-gray !important;
        background-color: rgba($c-gray, .1);
        padding: 6px 10px;
        border-radius: 6px;
        margin-right: 8px;

        &:hover {
          color: $c-white !important;
          background-color: rgba($c-gray, 1);
        }
      }
    }
  }

  &--nextPrev {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .item {
      background: rgba($c-main, .06);
      padding: 12px 16px;
      border-radius: 6px;
      width: 42%;
      text-align: center;
      min-height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      @media (max-width: 800px) {
        width: 49%;
        height: auto;
      }

      .a {
        text-decoration: none;
        color: $c-main !important;
      }

      p {
        margin-bottom: 0;
      }

      
    }
    .txt--right {
      text-align: right;
    }
  
  }
}

.head--blog {
  font-size: clamp(24px, 3vw, 32px);
  font-weight: 600;
  line-height: clamp(32px, 3vw, 42px);
  color: $c-white;
}