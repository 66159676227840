.news--area {
    background-color: #f0f4f8;

    .article--item {
        background-color: $c-white;
        border-radius: 4px;
        box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
        cursor: pointer;
        margin-bottom: 24px;
        .head {
            height: 320px;
            width: 100%;
            padding: 20px;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
            background-size: cover;
            position: relative;
            img {
                width: 100%;
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
            }

            @media (max-width: 1199px) {
                height: 260px;
            }

            .overlay {
                position: absolute;
                bottom: 16px;
                right: 0;
                left: 0;
                padding: 0 16px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                width: 100%;

                .tag {
                    background-color: $c-white;
                    border: 2px;
                    padding: 8px 16px;
                    color: $c-second;
                    width: fit-content;
                }
    
                .date {
                    color: $c-white;
                    font-size: 20px;
                    font-weight: 600;
                }
            }

            .action--btn {
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                top: 0;
                margin: auto;
                display: none;
                align-items: center;
                justify-content: center;
                transition: all 1s ease;
                opacity: 0;
                
            }
            
        }
        .content {
            padding: 16px;
            height: 140px;
            display: flex;
            flex-direction: column;
            justify-content: center;

            @media (max-width: 1199px) {
                height: 100px;
            }
          h2 {
              font-size: clamp(12px, 3vw, 20px);
              font-weight: 800;
              line-height: 24px;
              color: $c-main;
              padding-bottom: 8px;
              text-align: center;
              @media (max-width: 1199px) {
                font-weight: 700;
                // font-size: clamp(18px, 3vw, 14px);
                font-size: clamp(12px, 3vw, 18px);

              }

              a {
                color: $c-main;

                &:hover {
                    color: $c-main;
                }
              }
          }

          p {
            font-size: clamp(12px, 3vw, 14px);
            font-weight: 600;
            line-height: 24px;
            color: $c-dark2;
            margin-bottom: 0;

            @media (max-width: 1199px) {
               display: none;
            }
          }
        }

        &:hover .head{
            .action--btn { 
                display: flex;
                transition: opacity 1s ease;
                opacity: 1;
            }
        }
      }


       /* the slides */
    .slick-slide {
        margin-left:16px;
      }
    
      /* the parent */
      .slick-list {
        margin-left:-16px;
      }
}
