.partner--area {

    .partner--item {
        border: 1px solid $c-light;
        box-shadow: rgba(214, 219, 223, 0.2) 0px 8px 8px;

        margin: 0 4px;

        img {
          width: 100%;
          height: auto;
        }
    }


     /* the slides */
     .slick-slide {
        margin-left:8px;
      }
    
      /* the parent */
      .slick-list {
        margin-left:-8px;
      }
}


