:focus {
    outline: none;
}


.subscribe--input {

    padding: 16px 0;

    label {
        color: $c-gray;
    }

    &--container {
        position: relative;

        display: flex;
        align-items: center;
        justify-content: center;

        i,em {
            position: absolute;
            left: 16px;
            top: 0;
            bottom: 0;
            color: $c-gray;
            opacity: .5;
            margin: auto 0;
            line-height: 56px;
        }

        .button {
            position: absolute !important;
            padding: 0 16px;
            right: 8px;
            top: 0;
            bottom: 0;
            line-height: 56px;
            margin: auto;
            text-transform: capitalize;
        }

        input {
            height: 56px;
            padding-left: 40px;
            font-weight: 600;
        }

        ::placeholder {
            /* Chrome, Firefox, Opera, Safari 10.1+ */
            font-weight: 400;
            opacity: .7;
        }

        :-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-weight: 400;
            opacity: .7;
        }

        ::-ms-input-placeholder {
            /* Microsoft Edge */
            font-weight: 400;
            opacity: .7;
        }
    }

}



.input--col {
    position: relative;
    letter-spacing: 1px;

    input {
        width: 100%;
        height: 45px;
        border: 1px solid #ECEFF4
    }
}

// .effect-20 ~ .focus-border:before,
// .effect-20 ~ .focus-border:after{content: ""; position: absolute; top: 0; left: 0; width: 0; height: 2px; background-color: $c-main; transition: 0.3s;}
// .effect-20 ~ .focus-border:after{top: auto; bottom: 0; left: auto; right: 0;}
// .effect-20 ~ .focus-border i:before,
// .effect-20 ~ .focus-border i:after{content: ""; position: absolute; top: 0; left: 0; width: 2px; height: 0; background-color: $c-main; transition: 0.4s;}
// .effect-20 ~ .focus-border i:after{left: auto; right: 0; top: auto; bottom: 0;}
// .effect-20:focus ~ .focus-border:before,
// .effect-20:focus ~ .focus-border:after,
// .has-content.effect-20 ~ .focus-border:before,
// .has-content.effect-20 ~ .focus-border:after{width: 100%; transition: 0.3s;}
// .effect-20:focus ~ .focus-border i:before,
// .effect-20:focus ~ .focus-border i:after,
// .has-content.effect-20 ~ .focus-border i:before,
// .has-content.effect-20 ~ .focus-border i:after{height: 100%; transition: 0.4s;}
// .effect-20 ~ label{position: absolute; left: 14px; width: 100%; top: 10px; color: #aaa; transition: 0.3s; z-index: -1; letter-spacing: 0.5px;}
// .effect-20:focus ~ label, .has-content.effect-20 ~ label{top: -18px; left: 0; font-size: 12px; color: $c-main; transition: 0.3s;}



.stepper--form {

    box-shadow: rgba(193, 193, 200, 0.25) 0px 0px 12px 0px, rgba(191, 191, 191, 0.3) 0px 0px 0px 0px;
    background-color: white;
    padding: 24px;
    border-radius: 4px;
    position: relative;

    .form-navigation {
        position: sticky;
        top: 100%;
        background: white;
        width: 100%;

        padding: 16px 0;

        button {
            border: none;
            height: 60px;
            padding: 8px 24px;
            width: 200px;
            border-radius: 4px;
        }

        .previous {
            outline: none;
            background-color: rgb(244, 244, 244);
            color: $c-gray;
            color: $c-black;
            
        }

        .next {
            outline: none;
            background-color: $c-second;
            color: $c-white;

            &:hover {
                background-color: $c-main  !important;
            }
        }

        .submit {
            outline: none;
            background-color: $c-second  !important;
            color: $c-white;

            &:hover {
                background-color: $c-main  !important;
            }
        }
    }


    .form-section {
        display: none;

        min-height: 350px;
    }

    .form-section.current {
        display: inherit;
    }


    .parsley-errors-list {
        margin: 2px 0 3px;
        padding: 0;
        list-style-type: none;
        color: red;
    }
}