.ts--tabs {
  .nav-tabs {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;

    .nav-item {
      width: 30%;
      border: none;
      text-align: center;

      .nav-link {
        border-bottom: 4px solid #CDCDCD;
        color: #CDCDCD;
        text-decoration: none;
        padding: 16px 0;
        border-top: none;
        border-left: none;
        border-right: none;
        font-size: clamp(16px, 3vw, 24px);
        font-weight: 700;

        @media (max-width: 1199px) {
          font-size: clamp(14px, 3vw, 18px);
        }

        &:hover {
          border-top: none;
          border-left: none;
          border-right: none;
        }
      }
    }

    .nav-item .nav-link.active {
      border-bottom: 4px solid $c-main;
      border-top: none;
      border-left: none;
      border-right: none;
      color: $c-main;
      font-weight: 800;

    }
  }

  .tab-content {
    padding-top: 16px;
  }

}


.values--tabs {
  .nav-tabs {
    margin-bottom: 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;
    column-gap: 4px;

    @media (max-width:991px) {
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 8px;

    }

    .nav-item {
      width: 19%;
      text-align: center;
      border: none;
      position: relative;

      @media (max-width:991px) {
        width: 32%;
        margin-bottom: 16px;
        flex-grow: 1;
      }

      .nav-link {
        background-color: #F8F8F8;
        color: $c-gray;
        text-decoration: none;
        padding: 18px 8px;
        font-size: clamp(12px, 3vw, 18px);
        font-weight: 700;
        border-radius: 4px;

        @media (max-width: 1199px) {
          font-size: 14px;
        }

        &:hover {
          border: none;
        }
      }
    }

    .nav-item .nav-link.active {
      color: $c-white;
      background-color: $c-second;
      font-weight: 800;
      border: none;

      &::after {
        position: absolute;
        bottom: -17px;
        left: 0;
        width: 16px;
        margin: 0 auto;
        right: 0;
        content: "";
        border: 8px solid;
        border-color: $c-second transparent transparent transparent;
      }

    }
  }

  .tab-content {
    padding: 16px;
    background-color: $c-bg-1;
    border: $c-bg-2;
    border-radius: 8px;
  }

}


.map--tabs {
  .nav-tabs {
    margin-bottom: 24px;
    // grid-gap: 12px;
    // grid-template-columns: repeat( auto-fit, minmax(250px, 1fr) );
    // grid-template-columns: repeat( 12, minmax(250px, 1fr) );
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: none;
    column-gap: 4px;


    @media (max-width:991px) {
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 8px;

    }

    .nav-item {
      // width: 33%;
      width: auto;
      flex-grow: 1;
      text-align: center;
      // border: none;
      position: relative;

      @media (max-width:991px) {
        width: 48%;
        margin-bottom: 16px;
      }

      .nav-link {
        background-color: $c-white;
        color: $c-dark-2;
        text-decoration: none;
        padding: 18px 8px;
        font-size: clamp(12px, 3vw, 18px);
        font-weight: 700;
        border-radius: 4px;
        border: 1px solid transparent;

        @media (max-width: 1199px) {
          font-size: 14px;
        }

        &:hover {
          // border: none;
        }
      }
    }

    .nav-item .nav-link.active {
      color: $c-white;
      background-color: $c-second;
      font-weight: 800;
      // border: none;

      &::after {
        position: absolute;
        bottom: -15px;
        left: 0;
        width: 16px;
        margin: 0 auto;
        right: 0;
        content: "";
        border: 8px solid;
        border-color: $c-second transparent transparent transparent;
      }

    }
  }

  .tab-content {
    background-color: $c-bg-1;
    border: $c-bg-2;
    border-radius: 8px;
  }

}



