//Sizing Variables
$grid-breakpoints: (
  xs: 0,
  ss: 380px,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

//Colours
$c-main: #006A42;
$c-main-dark: #113c35;
$c-main-dark2: #0F2F2A;
$c-second: #149D69;
$c-third: #283C98;
$c-dark: #231F20;
$c-dark2: #6A738A;
$c-light: #E4EEEA;

$c-bg-1: #F7FCFB;
$c-bg-2: #E2EEEA;

$c-background: #F8F8FC;
$c-background2: #F4FCF9;
$c-black: #303030;
$c-white: #ffffff;
$c-gray: #57677D;
$c-gray-light: #EBEFF5;
$c-gray-l2: #F8F8FF;
$c-gray-64: #B8BBC4;

$c-blue-dark: #002E74;
$c-dark-2: #3D4864;


$c-grey-33: #333333;
$c-grey-f7: #f7f7f7;
$c-grey-ab: #ababab;
$c-grey-b9: #b9b9b9;



