/* HERO SECTION */
// height: calc(96vh - 75px);
// background-color: $c-main;
// // display: flex;
// // align-items: center;
// z-index: 1;
// padding-top: 60px;
// @media (max-width: 600px) {
//   text-align: center;
// }

// @media (max-width: 1366px) {
//   // height: auto;
//   padding-top: 60px;
//   padding-bottom: 60px;
// }

// @media (max-height: 768px) {
//   // height: auto;
//   // padding-top: 40px ;
// }

// @media (max-width: 991px) {
//   height: auto;
//   padding-top: 20px;
//   margin-bottom: 0;
// }

.pos-relative {
  position: relative;
  height: 100%
}
.hero--section {

  .carousel-item {
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      height: calc(100vh - 70px);
      transition: -webkit-transform 2s ease;
      transition: transform 2s ease;
      transition: transform 2s ease, -webkit-transform 2s ease;

      @media (max-height: 768px) {
          height: 700px;
      }

      @media (max-width: 991px) {
        height: 700px;
      }

      @media (max-width: 600px) {
        min-height: 500px;
        height: 500px;
      }
  }
  &--caption {
      position: absolute;
      // right: 15%;
      top: 0;
      bottom: 0;
      display: flex !important;
      flex-direction: column;
      left: 0%;
      align-items: flex-start;
      justify-content: center;
      padding-top: 1.25rem;
      padding-bottom: 1.25rem;
      color: #fff;
      text-align: left;
      animation-duration: 1s;
      animation-delay: 2s;
      width: 80%;
      @media only screen and (max-width: 1199px) {
        width: 100%;
      }
      
    
      &--title {
          --minFontSize: 24px;
          --maxFontSize: 42px;
          text-transform: uppercase;
          text-align: start;
          --scaler: 3vw;
          font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
          font-weight: 800;
          padding: 8px 0;
          color: $c-white;
          // text-shadow: 2px 2px $c-main;
          animation-duration: 1s;
          animation-delay: 1.5s;
      }
      &--subtitle {
          --minFontSize: 16px;
          --maxFontSize: 24px;
          --scaler: 5vw;
          font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
          line-height: 40px;
          font-weight: 300;
          text-align: start;
          animation-duration: .5s;
          animation-delay: 1.4s;
          width: 60%;
          @media only screen and (max-width: 1199px) {
            width: 100%;
            line-height: 32px;
          }
      }
      &--actions {
        display: flex;
        align-items: center;
        justify-content: center;
        animation-duration: 1s;
        animation-delay: 2.2s;
    }
  }
  .carousel-indicators {
    left: unset;
    right: 0;
    margin-right: 2%;
    bottom: 0%;
    top: 0%;
    flex-direction: column;
    button {
      background-color: $c-white;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }
  }

  .carousel-control-prev, .carousel-control-next {
    width: 40px;
    height: 40px;
    top: unset !important;
    bottom: 2%;
    margin: auto;
    left: 0;
  }

  
  .carousel-control-prev {
    right: 4%;
    @media (max-width: 991px) {
      right: 80%;
    }
  }
  .carousel-control-next {
    left: 4%;
    @media (max-width: 991px) {
      left: 80%;
    }
  }
}
