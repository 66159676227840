.careers--area {

  &--list {
    &--item {
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background-color: $c-bg-1;
      border-radius: 6px;
      border: 1px solid $c-bg-2;
      padding: 24px 24px;
      box-shadow: rgba(220, 220, 220, 0.2) 0px 0px 16px;
      cursor: pointer;

      .left {
        display: flex;
        align-items: center;
      }

      &:hover {
        box-shadow: none;
      }
      &--title {
        margin-bottom: 0;
        font-size: clamp(16px, 3vw, 18px);
        font-weight: 600;
        color: $c-dark-2;
        margin-left: 16px;
      }
    }
  }
}

.collaborators--area {
  background-color: $c-bg-1;

  &--item {
    background-color: $c-white;
    box-shadow: rgba(244, 244, 244, 0.25) 0px 0px 12px 0px, rgba(240, 240, 240, 0.3) 0px 0px 10px 0px;
    margin: 4px;
    border-radius: 8px;
    
    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    height: 270px !important;

    @media (max-width: 580px) {
        flex-direction: column;
        justify-content: flex-start;
        height: auto !important;
    }

    img {
        width: 25%;
        border-top-left-radius: 8px;
        border-bottom-left-radius: 8px;
        height: 270px !important;
        object-fit: cover;

        @media (max-width: 580px) {
            width: 100%;
            border-top-left-radius: 8px;
            border-top-right-radius: 8px;
        }
    }

    &--content {
        padding: 24px;
        width: 75%;
        position: relative;
        @media (max-width: 991px) {
            padding: 16px;
        }
        @media (max-width: 580px) {
            width: 100%;
        }

        h3 {
            font-size: clamp(14px, 3vw, 16px);
            font-weight: 800;
            line-height: 28px;
            color: $c-second;
            margin-top: 16px;
            @media (max-width: 991px) {
                margin-top: 8px;
            }
        }

        p {
            font-size: clamp(14px, 3vw, 18px);
            font-weight: 500;
            color: $c-dark-2;
            line-height: 32px;
            margin-bottom: 0;
            @media (max-width: 991px) {
                font-size: 14px;
                line-height: 24px;
            }
        }

        .icon, i,em {
          font-size: clamp(32px, 3vw, 60px);
          color: rgba($c-gray,.2);
          position: absolute;
          right: 4%;
          bottom: 2%;
        }

    }

}
}

.careers--area--form {
    background-color: #ffffff;

    &--title {
        color: $c-second;
        --minFontSize: 18px;
        --maxFontSize: 26px;
        --scaler: 3vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
        position: relative;
        font-weight: 500;
        text-transform: uppercase;
        padding-bottom: 20px ;
      }

}

.careers--area--single {
 
  &--content {
    &--grp {
      margin-top: 16px;
      background-color: $c-bg-1;
      border-radius: 8px;
      padding: 32px 24px;
      border: 1px solid $c-bg-2;

      &--body {
        padding: 16px 0 24px;

        & > * {
          line-height: 28px;
        }
      }
      &--action {
        text-align: center;
        width: 100%;
        &--btn {
          margin: 0 auto;
          color: $c-second;
          --minFontSize: 13px;
          --maxFontSize: 16px;
          --scaler: 3vw;
          font-size: clamp(
            var(--minFontSize),
            var(--scaler),
            var(--maxFontSize)
          );
          position: relative;
          word-spacing: 0;
          font-weight: 500;
          text-transform: uppercase;
          text-align: center;
          cursor: pointer;
          border-bottom: 0.5px solid $c-second;
          &:hover {
            color: $c-third;
            border-bottom: 0.5px solid $c-third;
          }
        }
      }
    }
  }

  &--right {

  @media (max-width: 991px) {
    padding-top: 32px;
  }    
    &--item {
      border-bottom: 1px solid #e6e6e6;
      padding-bottom: 24px;
      padding-top: 24px;
      &:first-child {
        padding-top: 0px;
      }
      &:last-child {
        padding-bottom: 0px;
        border-bottom: none;
      }
      &--head {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        h5 {
          padding-left: 8px;
          margin-bottom: 0;
          --minFontSize: 14px;
          --maxFontSize: 16px;
          --scaler: 3vw;
          font-size: clamp(
            var(--minFontSize),
            var(--scaler),
            var(--maxFontSize)
          );
          font-weight: 400;
          color: $c-dark-2;
        }
      }

      &--value {
        --minFontSize: 14px;
        --maxFontSize: 16px;
        --scaler: 3vw;
        font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
        font-weight: 500;
        color: $c-dark;
        padding-top: 10px;
        padding-left: 28px;
      }
    }
  }
  &--action {
    padding-top: 24px;
    text-align: center;

    .button {
      width: 80% !important;
    }
  }
}
