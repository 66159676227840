
.footer--section--area {
  background-image: linear-gradient(120deg, rgba(20, 157, 105, 1)  , rgb(0, 106, 66));

  padding: 40px 0 0;

  .widget--item {

    @media (max-width: 991px) {
        padding: 24px 0;
        border-bottom: .6px solid $c-white;
    }

    img {
      height: 70px;
      margin-bottom: 16px;
    }

    p {
      color: $c-white;
      font-size: clamp(14px,3vw, 16px);
      line-height: 24px;
      font-weight: 400;
      padding: 16px 0;
      margin-bottom: 0;
    }

    .social {

      .icon, i.icon,em.icon {
        color: $c-white !important;
        font-size: 38px !important;
      }
    }

    .infos {
      display: flex;
      align-items: center;
      padding-bottom: 16px;
      a {
        text-decoration: none;
        color: $c-white;
        padding-left: 16px;
        font-size: 16px;
        font-weight: 600;
      }

      .infos--arabic {
        padding-left: 0 !important;
        padding-right: 16px !important;
      }
    }

  }



  .copyright-area{
  // background-color: #ffffff;

  padding: 24px 0;
  }
  .copyright-text p {
  margin: 0;
  font-size: 14px;
  color: $c-white;
  }
  .copyright-text p a{
  color: $c-white;
  }
  .footer-menu ul {
    text-align: right;
  }
  .footer-menu li {
  display: inline-block;
  margin-left: 20px;
  }
  .footer-menu li:hover a{
  color: $c-white;
  }
  .footer-menu li a {
  font-size: 14px;
  color: $c-white;
  }
}



