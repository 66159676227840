.primary--title {
  width: max-content;
  h2 {
    color: $c-main;
    --minFontSize: 24px;
    --maxFontSize: 38px;
    --scaler: 3vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 600;
    // text-align: left;
    padding-left: 54px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      margin: auto;
      bottom: 0;
      top: 0;
      height: 6px;
      border-radius: 0px;
      width: 40px;
      background-color: $c-main;
    }
    
  }
}

.second--title {
  h1 {
    color: $c-main;
    --minFontSize: 18px;
    --maxFontSize: 24px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 700;
    // text-align: left;
    padding-left: 48px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      margin: auto;
      bottom: 0;
      top: 0;
      height: 5px;
      border-radius: 0px;
      width: 32px;
      background-color: $c-main;
    }
    
  }

  h2 {
    color: $c-main;
    --minFontSize: 18px;
    --maxFontSize: 24px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 700;
    // text-align: left;
    padding-left: 48px;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      margin: auto;
      bottom: 0;
      top: 0;
      height: 5px;
      border-radius: 0px;
      width: 32px;
      background-color: $c-main;
    }
    
  }
}

.primary--title--small {
  width: max-content;
  h4 {
    color: $c-white;
    --minFontSize: 14px;
    --maxFontSize: 18px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 600;
    // text-align: left;
    padding-left: 40px;
    text-transform: uppercase;

    &:before {
      content: "";
      position: absolute;
      left: 0;
      margin: auto;
      bottom: 0;
      top: 0;
      height: 2px;
      border-radius: 0px;
      width: 30px;
      background-color: $c-white;
    }
    
  }
}

.primary--title--2 {
  width: max-content;
  h1 {
    color: $c-main;
    --minFontSize: 24px;
    --maxFontSize: 38px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 800;
    // text-align: left;
    line-height: 48px;

  }
  h2 {
    color: $c-main;
    --minFontSize: 24px;
    --maxFontSize: 38px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 800;
    // text-align: left;
    line-height: 48px;

  }
}


.primary--title--center {
  text-align: center;
  h2 {
    color: $c-second;
    --minFontSize: 24px;
    --maxFontSize: 38px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 800;
    line-height: 48px;

  }

  h1 {
    color: $c-second;
    --minFontSize: 24px;
    --maxFontSize: 38px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 800;
    line-height: 48px;

  }
}

.heading--squar{
  h4 {
   color: $c-third;
   --minFontSize: 16px;
   --maxFontSize: 20px;
   --scaler: 3vw;
   font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
   position: relative;
   word-spacing: 0;
   font-weight: 700;
   text-transform: uppercase;
   z-index: 9;

 
   &:before {
     background: rgba($c-gray, .2);
     display: block;
     content: "";
     position: absolute;
     width: 16px;
     height: 16px;
     margin: 0 auto;
    //  opacity: .2;
     left: -8;
     bottom: -4px;
     z-index: -1;
    
   }
 
  }

 }

.gray--title--center {
  text-align: center;
  h3 {
    color: $c-gray-64;
    --minFontSize: 24px;
    --maxFontSize: 32px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 700;
    line-height: 48px;
  }
}

.blue--title--center {
  text-align: center;
  h4 {
    color: $c-third;
    --minFontSize: 20px;
    --maxFontSize: 28px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    position: relative;
    word-spacing: 0;
    font-weight: 700;
    line-height: 48px;
  }
}






.t--paragraph--1 {
  --minFontSize: 16px;
  --maxFontSize: 20px;
  --scaler: 4vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 400;
  line-height: 38px;
  color: $c-gray;
  // text-align: left;

  &:last-child {
    margin-bottom: 0;
  }

}

.t--paragraph--large {
  --minFontSize: 16px;
  --maxFontSize: 24px;
  --scaler: 4vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 400;
  line-height: 42px;
  color: $c-dark-2;
  padding-bottom: 8px;
  // text-align: left;

}

.t--paragraph--black {
  --minFontSize: 14px;
  --maxFontSize: 18px;
  --scaler: 5vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 400;
  line-height: 32px;
  color: $c-black;
  padding-bottom: 8px;
  text-align: justify;

  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }

  @media (max-width: 1366px) {
    --minFontSize: 13px;
    --maxFontSize: 16px;
    --scaler: 5vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    line-height: 24px;


  }

  @media (max-width: 800px) {
    line-height: 24px;
  }

}

.t--paragraph--small {
  --minFontSize: 13px;
  --maxFontSize: 16px;
  --scaler: 7vw;
  font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
  font-weight: 400;
  line-height: 28px;
  color: $c-dark-2;
  padding-bottom: 8px;
  // text-align: left;


  &:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
  }
}


.list--style--01 {
  list-style: none;
  padding-left: 1rem;

  li {
    padding: 4px 0;
    --minFontSize: 14px;
    --maxFontSize: 18px;
    --scaler: 4vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    font-weight: 400;
    line-height: 32px;
    color: $c-dark-2;
    span {
      padding-left: 8px;
    }
    &::marker {
      content: "✔️";
    }
  }
}

.list--style--02 {
  list-style: none;
  padding-left: 0rem;

  li {
    padding: 4px 0;
    --minFontSize: 14px;
    --maxFontSize: 18px;
    --scaler: 4vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    font-weight: 600;
    line-height: 32px;
    color: $c-second;
    span {
      padding-left: 8px;
    }
  }
}

.list--style--03 {
  list-style: none;
  padding-left: 0rem;
  display: flex;
  align-items: center;
  justify-content: center;
  column-gap: 16px;
  flex-wrap: wrap;

  li {
    background-color: $c-background2;
    border-radius: 4px;
    margin-bottom: 16px;
    padding: 24px;
    --minFontSize: 14px;
    --maxFontSize: 18px;
    --scaler: 4vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    font-weight: 600;
    line-height: 32px;
    color: $c-second;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 48%;
    border: 1px solid $c-second;

    @media (max-width: 991px) {
      width: 31%;
      padding: 16px;
    }
    @media (max-width: 600px) {
      width: 48%;
    }
    @media (max-width: 400px) {
      width: 46%;
    }

    span {
      padding-top: 12px;
    }
  }
}


.s--image {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 12px;
  height: auto;

  &:hover img {
    transform: scale(1.5);
  }

  img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    transition: all .8s;
  }
}

.s2--image {
  width: 80%;
  padding: 12px;
  height: auto;

}

.s--image-2 {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 12px;

  &:hover img {
    transform: scale(1.5);
  }

  img {
    width: 100%;
    border-radius: 10px;
    object-fit: cover;
    transition: all .8s;
  }

  @media (max-width: 800px) {
    width: 200px;
    margin: 0 auto;
  }
}

.s--image--prim {
  width: 100%;
  height: 100%;

  @media only screen and (max-width: 991px) {
    padding: 24px;
    margin-top: 24px;
  }
}

.image--random {
  position: relative;

  &--01 {
    transition: all .5s;
    transition-property: transform;

    &:hover {
      transform: scale(1.1) rotate(1.5deg) translateY(-12px) translateX(-2px);
    }

    padding-left: 80px;
  }

  &--02 {
    position: absolute;
    bottom: -114px;
    left: 7%;
    width: 70%;
    border-radius: 12px;
    transform-origin: left bottom;
    transition: all .5s;
    transition-property: transform;

    &:hover {
      transform: scale(1.1) rotate(1.5deg) translateX(12px) translateY(-12px);
    }

    @media (max-width: 800px) {
      bottom: -90px;
      left: 4%;
      width: 70%;
    }
  }

  &--03 {
    position: absolute;
    right: -40px;
    width: 30%;
    border-radius: 12px;
    transition: all .5s;
    transition-property: transform;

    &:hover {
      transform: scale(1.15) rotate(-2deg);
    }

    @media (max-width: 800px) {
      right: 0;
      width: 35%;
    }
  }

}



.image--style--1 {
  padding-left: 24px;

  @media (max-width: 991px) {
    padding: 24px;
  }

  img {
    width: 100%;
    height: 100%;
    border-radius: 4px;
  }
}


.m--bold--primary {
  font-weight: 600;
  color: $c-main;
}

.text--color--third {
  color: $c-third;
}










.animate--float {
  overflow: hidden;
  transform: translatey(0px);
  animation: float 6s ease-in-out infinite;
}


.animate--float--1 {
  overflow: hidden;
  transform: translatey(0px);
  animation: float1 6s ease-in-out infinite;
}

.animate--float--2 {
  overflow: hidden;
  transform: translateX(0px);
  animation: float1 6s ease-in-out infinite;
}



@keyframes float {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(-10px);
  }

  100% {
    transform: translatey(0px);
  }
}


@keyframes float1 {
  0% {
    transform: translatey(0px);
  }

  50% {
    transform: translatey(10px);
  }

  100% {
    transform: translatey(0px);
  }
}

@keyframes float2 {
  0% {
    transform: translateX(0px);
  }

  50% {
    transform: translateX(-80px);
  }

  100% {
    transform: translateX(0px);
  }
}




@keyframes animateLine {
  0% {
    transform: scaleX(0);
    transform-origin: left;
  }

  50% {
    transform: scaleX(1);
    transform-origin: left;
  }

  50.1% {
    transform: scaleX(1);
    transform-origin: right;

  }

  100% {
    transform: scaleX(0);
    transform-origin: right;

  }


}


.w-50 {
  width: 50%;
}

.border--top--light {
  border-top: 2px solid $c-bg-2;
}