.numbers--area {
    position: relative;

    &--item {
        background-color: $c-second;
        text-align: center;
        padding: 32px;
        display: flex !important;
        height: 220px !important;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        z-index: 1;

        @media (max-width: 1199px) {
            padding: 24px;
        }

        img {
            height: auto;
            width: auto;
            margin-bottom: 16px;
        }
        h4 {
            font-size: clamp(32px, 3vw, 42px);
            font-weight: 800;
            color: $c-white;
            @media (max-width: 1199px) {
                font-size: clamp(24px, 3vw, 32px);
            }

            .size {
                font-size: clamp(16px, 3vw, 20px);
                font-weight: 500;
            }
        }

        p {
            font-size: clamp(14px, 3vw, 16px);
            font-weight: 500;
            color: $c-white; 
        }


    }

    .style--01 {
        background-color: $c-main;
    }

    .shape01 {
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: -1;
        width: 100%;
    }

    .numbers--web {
        @media (max-width: 991px) {
            display: none;
        }
    }
    .numbers--mobile {
        display: none;
        @media (max-width: 991px) {
            display: block;
        }
    }

    
}