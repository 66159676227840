.primary--color {
  color: $c-second;
}

.about--area {
  &--pdg {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-top: 24px;

    img {
      height: 70px;
      width: 70px;
      border-radius: 4px;
    }

    &--content {
      padding-left: 16px;

      h3 {
        padding-bottom: 0;
        font-size: clamp(16px, 3vw, 24px);
        color: $c-main-dark2;
        font-weight: 700;
      }

      span {
        font-size: clamp(16px, 3vw, 20px);
        font-style: italic;
        color: $c-dark2;
        font-weight: 500;
      }
    }

    .arabic {
      padding-left: 0 !important;
      padding-right: 16px;
    }
  }
}

.about--area--vision {
  background-color: $c-bg-1;

  .quotes {
    padding-top: 24px;
    font-weight: 900;
    color: $c-second;
    font-size: clamp(24px, 3vw, 38px);
    line-height: 48px;

    @media (max-width: 991px) {
      text-align: center;
      font-size: clamp(18px, 3vw, 28px);
    }
  }
}

.list--icon {
  list-style-image: url("/assets/images/logo--icon.svg");
  padding-left: 2rem;

  li {
    span {
      position: relative;
      left: 16px;
    }

    .arabic--list--right {
      right: 16px;
      left: unset;
    }

    @media (max-width: 700px) {
      width: 90%;
      line-height: 32px;
    }
  }
}

.arabic--list--icon {
  padding-right: 2rem;
  padding-left: 0rem;
}

.about--area--values {
  border-top: 2px solid $c-bg-2;
  // background-color: rgba($c-dark2, .04);
}

.nbrs--area {
  background-color: rgba($c-dark2, 0.03);
}

.about--area--aires {
  .card--item {
    background-color: $c-background2;
    padding: 16px;
    border-radius: 4px;
    height: 140px;

    @media (max-width: 1399px) {
      height: 160px;
    }

    @media (max-width: 1199px) {
      height: 170px;
    }

    @media (max-width: 991px) {
      margin-bottom: 16px;
      height: 140px;
    }

    @media (max-width: 767px) {
      height: 120px;
    }

    .head {
      display: flex;
      align-items: center;
      padding-bottom: 0px;
      margin-bottom: -10px;
      h3 {
        font-size: 24px;
        font-weight: 800;
        color: $c-second;
        padding-left: 8px;
      }
    }

    span {
      color: $c-main;
      font-size: 12px;
    }

    p {
      margin-bottom: 0;
      color: $c-main-dark;
      font-size: 14px;
      margin-top: 8px;

      small {
        color: $c-main;
        font-size: 12px;
      }
    }
  }
}

.aires--area--accordion {
  padding-bottom: 60px;

  .accordion {
    font-size: 1rem;
    width: 100%;
    margin: 0 auto;
    border-radius: 4px;
  }

  .accordion-header,
  .accordion-body {
    background: white;
  }

  .accordion-header {
    padding: 16px 16px;
    background: $c-second;
    border-radius: 4px;
    //   border: 1.5px solid #E2EEEA;
    color: $c-white;
    cursor: pointer;
    font-size: 16px;
    font-weight: 600;
    transition: all 0.3s;
    text-transform: uppercase;
  }

  .accordion__item {
    // border-bottom: 1px solid #3a4ba4;
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion__item .accordion__item {
    //   border-bottom: 1px solid rgba(0, 0, 0, 0.08);
  }

  .accordion-header:hover {
    // background: $c-white;
    position: relative;
    z-index: 5;
  }

  .accordion-body {
    background: $c-background2;
    border-radius: 4px;
    color: $c-dark;
    display: none;
    padding: 1rem 1.25rem;

    @media (max-width: 600px) {
      padding: 8px;
    }
  }

  .accordion-sub-body {
    background: $c-white;
    border-radius: 4px;
    color: $c-dark;
    display: none;
    padding: 12px 16px;

    border: 1px solid $c-bg-2;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-top: 0;

    @media (max-width: 600px) {
      padding: 4px 0px;
    }
  }

  .accordion-body__contents {
    padding: 16px;
    font-size: 0.85em;
  }

  .accordion__item.active:last-child .accordion-header {
    border-radius: none;
  }

  .accordion:first-child > .accordion__item > .accordion-header {
    //   border-bottom: 1px solid transparent;
  }

  .accordion__item > .accordion-header:after {
    content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='lightgray' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-chevron-down'%3E%3Cpolyline points='6 9 12 15 18 9'%3E%3C/polyline%3E%3C/svg%3E");
    // font-family: IonIcons;
    font-size: 1.2em;
    float: right;
    position: relative;
    top: -2px;
    transition: 0.3s all;
    transform: rotate(0deg);
  }

  .rightIcon:after {
    float: left !important;
  }

  .accordion__item.active > .accordion-header:after {
    transform: rotate(-180deg);
  }

  .accordion__item.active .accordion-header {
    background: $c-main;
  }

  .accordion__item .accordion__item .accordion-header {
    background: $c-white;
    color: $c-dark-2;
    border: 1px solid $c-bg-2;
    border-radius: 4px;
  }

  .accordion__item .accordion__item.active .accordion-header {
    border: 1px solid $c-bg-2;
    border-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media screen and (max-width: 1000px) {
  

    .accordion {
      width: 100%;
    }
  }

  table {
    border: 1px solid $c-second;
    width: 100%;

    th {
      border: 1px solid $c-second;
      border-collapse: collapse;
      padding: 8px 16px;
      font-size: 16px;
      color: $c-second;
    }

    td {
      border: 1px solid $c-second;
      border-collapse: collapse;
      padding: 16px;
      color: $c-dark2;
      @media (max-width: 600px) {
        padding: 8px;
        font-size: 12px;
      }
    }

    .list {
      border: 1px solid $c-second;
      border-collapse: collapse;
      color: $c-dark2;
      padding: 0 !important;

      ul {
        list-style: none;
        padding-left: 0;
        margin-bottom: 0;

        li {
          border-bottom: 1px solid rgba($c-second, 0.5);
          padding: 16px;

          @media (max-width: 600px) {
            padding: 8px;
            font-size: 12px;
          }

          &:last-child {
            border-bottom: 0;
          }
        }
      }
    }
  }
}

.partner--area {
  .border--top--green {
    border-top: 3px solid $c-main !important;
  }

  .value--item {
    background-color: $c-white;
    padding: 24px;
    border-radius: 8px;
    height: 260px;
    box-shadow: rgba(193, 193, 200, 0.25) 0px 6px 12px -2px,
      rgba(191, 191, 191, 0.3) 0px 3px 7px -3px;
    border-top: 3px solid $c-second;

    @media (max-width: 1399px) {
      height: 280px;
    }

    @media (max-width: 1199px) {
      height: 300px;
    }

    @media (max-width: 991px) {
      margin-bottom: 16px;
      height: 260px;
    }

    @media (max-width: 767px) {
      height: 240px;
      padding: 16px;
    }

    .head {
      display: flex;
      align-items: center;
      flex-direction: column;

      img {
        margin-bottom: 8px;
      }

      h3 {
        font-size: 16px;
        font-weight: 700;
        color: $c-dark;
        margin: 16px 0;
        text-align: center;
      }
    }

    p {
      margin-bottom: 0;
      color: $c-dark2;
      font-size: 14px;
      text-align: center;
    }
  }
}

.partner--area--form {
  background-color: $c-background;
}

.pv--area {
  background-color: $c-white;
}

.form--area {
  .uploadFile {
    overflow: hidden;
    position: relative;
    resize: none;

    font-size: 16px;
    font-weight: 600;
    color: $c-dark2;
    // height: 60px;
    border-radius: 4px;
    cursor: pointer;
    background-color: $c-background2;
    border: 1px solid rgba($c-second, 0.3);

    padding: 16px 24px;
    width: 100%;

    .icon, i,em {
      margin-right: 8px;
    }

    &::placeholder {
      font-weight: 500;
    }

    [type="file"] {
      cursor: pointer !important;
      display: block;
      font-size: 999px;
      filter: alpha(opacity=0);
      min-height: 100%;
      min-width: 100%;
      opacity: 0;
      position: absolute;
      right: 0px;
      text-align: start;
      top: 0px;
      z-index: 1;
    }
  }

  .item--group {
    width: 100%;
    margin-bottom: 16px;
    // display: flex;
    // flex-direction: column;
    text-align: start;

    input,
    select,
    textarea {
      font-size: 16px;
      font-weight: 700;
      color: $c-dark2;
      // height: 60px;
      border-radius: 4px;

      background-color: $c-background2;
      border: 1px solid rgba($c-second, 0.3);

      padding: 16px 24px;
      width: 100%;

      &::placeholder {
        font-weight: 500;
      }
    }

    label {
      text-align: start;
      margin-bottom: 8px;
    }

    select {
      display: inline-block;

      margin: 0;
      -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-appearance: none;
      -moz-appearance: none;

      background-position: calc(100% - 24px) calc(1.4em + 2px),
        calc(100% - 19px) calc(1.4em + 2px), calc(100% - 3em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 2.2em;
      background-repeat: no-repeat;
      // background-image:
      //     linear-gradient(45deg, transparent 60%, gray 60%),
      //     linear-gradient(135deg, gray 60%, transparent 60%),
      //     linear-gradient(to right, #ccc, #ccc);
    }

    select:focus {
      // background-image:
      //     linear-gradient(45deg, $c-second 50%, transparent 50%),
      //     linear-gradient(135deg, transparent 50%, $c-second 50%),
      //     linear-gradient(to right, #ccc, #ccc);
      background-position: calc(100% - 19px) 1.4em, calc(100% - 24px) 1.4em,
        calc(100% - 3em) 0.5em;
      background-size: 5px 5px, 5px 5px, 1px 2.2em;
      background-repeat: no-repeat;
      border-color: $c-second;
      outline: 0;
    }
  }

  .item--group--50 {
    width: 49% !important;
  }
  .item--group--33 {
    width: 32% !important;
  }

  .upload--file {
    // width: 100%;
    // position: relative;

    .custom-file__input {
      position: absolute;

      width: 100%;
      height: 100%;

      opacity: 0;
      overflow: hidden;
      z-index: -1;
    }

    .custom-file__label {
      position: relative;

      color: $c-dark2;
      border-radius: 4px;

      background-color: $c-background2;
      border: 1px solid rgba($c-second, 0.3);

      padding: 16px 24px;
      height: 60px;
      width: 100%;

      // line-height: 24px;
      text-align: left;
      text-transform: none;
      display: flex;
      align-items: center;
      padding-left: 24px;

      cursor: pointer;
      transition: all 0.3s;
    }

    // .custom-file__input:valid~.custom-file__label {
    //     border-color: green;
    //     background: green;
    //     color: green;

    //     &:before {
    //         content: '\f00c';

    //         position: absolute;
    //         top: 0;
    //         right: 0;
    //         bottom: 0;
    //         left: 0;

    //         margin: auto;

    //         color: white;
    //         font-family: 'FontAwesome';
    //         line-height: 45px;
    //     }
    // }

    // .icon {
    //     position: absolute;
    //     right: 0;
    //     top: 0;
    //     bottom: 0;
    //     margin: auto 0;
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     background-color: $c-second;
    //     color: $c-white;
    //     width: 60px;
    //     border-bottom-right-radius: 4px;
    //     border-top-right-radius: 4px;

    // }
  }

  .groups {
    // margin: 32px 0;
    border-bottom: 1.5px solid rgba($c-gray, 0.2);
    padding-bottom: 24px;
    display: flex;
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
    &:last-child {
      border-bottom: 0;
      padding-bottom: 0;
    }
    &:first-child {
      margin-top: 0;
    }

    h5 {
      color: $c-main;
      padding-bottom: 8px;
      font-weight: 600;
    }
    h4 {
      color: $c-third;
      margin-bottom: 8px;
      font-weight: 500;
    }
  }

  .sub--group {
    width: 48%;
    @media (max-width: 991px) {
      width: 100%;
    }
  }

  .td {
    width: 20% !important;
    align-items: center;
  }
}

.innov--area--speciality {
  background-color: $c-bg-1;

  &--item {
    background-color: $c-white;
    box-shadow: rgba(244, 244, 244, 0.25) 0px 0px 12px 0px,
      rgba(240, 240, 240, 0.3) 0px 0px 10px 0px;
    margin: 4px;
    border-radius: 8px;

    display: flex !important;
    align-items: center;
    justify-content: flex-start;
    height: 270px !important;

    @media (max-width: 1199px) {
      height: auto !important;
    }

    @media (max-width: 580px) {
      flex-direction: column;
      justify-content: flex-start;
      height: auto !important;
    }

    img {
      width: 30%;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      height: 270px !important;
      object-fit: cover;

      // @media (max-width: 1199px) {
      //     height: 100% !important;
      // }

      @media (max-width: 580px) {
        width: 100%;
        border-top-left-radius: 8px;
        border-top-right-radius: 8px;
      }
    }

    &--content {
      padding: 24px;
      width: 70%;

      @media (max-width: 991px) {
        padding: 16px;
      }

      @media (max-width: 580px) {
        width: 100%;
      }

      h3 {
        font-size: clamp(16px, 3vw, 32px);
        font-weight: 800;
        color: $c-second;
        margin-bottom: -8px;
      }

      span {
        color: $c-main;
        font-size: 12px;
      }

      p {
        font-size: clamp(14px, 3vw, 18px);
        font-weight: 500;
        color: $c-dark-2;
        line-height: 32px;
        margin-bottom: 0;
        margin-top: 8px;

        @media (max-width: 991px) {
          font-size: 14px;
          line-height: 24px;
        }
      }
    }
  }
}

.innov--area--infos {
  .item {
    background-color: $c-background2;
    padding: 16px;
    border-radius: 4px;
    margin-bottom: 16px;

    .head {
      display: flex;
      align-items: center;

      h3 {
        font-size: 18px;
        font-weight: 700;
        color: $c-second;
        padding-left: 12px;
        margin-bottom: 0;
      }
    }
  }
}

.contact--area--form {
  &--card {
    border: 1.5px solid $c-bg-2;
    background-color: $c-white;
    border-radius: 4px;
    padding: 16px;
    margin-bottom: 16px;

    @media (max-width: 991px) {
      &:first-child {
        margin-top: 32px;
      }
    }

    .item {
      padding-bottom: 16px;

      &:last-child {
        padding-bottom: 0;
      }

      .head {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-bottom: 8px;

        h4 {
          padding-left: 12px;
          color: $c-third;
          font-size: 16px;
          font-weight: 600;
          text-transform: uppercase;
          margin-bottom: 0;
        }

        .--arabic {
          padding-left: 0 !important;
          padding-right: 12px !important;
        }
      }

      p {
        margin-bottom: 0;
        color: $c-dark-2;
        text-align: start;
        width: fit-content;
      }
    }
  }
}

.contact--area--map {
  background-color: $c-background2;

  iframe {
    width: 100%;
    height: 560px;
  }
}
