.section--100 {
  padding: 100px 0;
}
.section--80 {
  padding: 80px 0;
}
.section--60 {
  padding: 60px 0;
}
.section--40 {
  padding: 40px 0;
}

.section--20 {
  padding: 20px 0;
}


.bg--home {
  background-image: linear-gradient(120deg,rgba(20, 157, 105, .8)  , rgba(20, 157, 105, .8) 75% ),url('/assets/images/bg--01.webp');
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  padding: 160px 0;
  

  h4 {
    margin-bottom: 0;
    text-align: center;
    --minFontSize: 32px;
    --maxFontSize: 50px;
    font-weight: 800;
    line-height:70px;
    --scaler: 7vw;
    font-size: clamp(var(--minFontSize), var(--scaler), var(--maxFontSize));
    color: $c-white;

    position: relative;

    @media (max-width: 600px) {
      line-height:50px; 
    }

    .left {
      position: absolute;
      left: 14%;
      top: -20px;

      @media (max-width: 991px) {
        left: -8%;
      }
      @media (max-width: 600px) {
        width: 40px;
        left: 0%;
        right: 0;
        top: -30px;
      }
    }

    .right {
      position: absolute;
      right: 14%;
      top: -20px;

      @media (max-width: 991px) {
        right: -8%;
      }
      @media (max-width: 600px) {
        width: 40px;
        right: 0;
        left: 0;
        top: 100px;
      }
    }
  }
}




.custom--slick {

  /* Slick Slider Css Ruls */

  .slick-slider {
    position: relative;
    display: block;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
  }

  .slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0
  }

  .slick-list:focus {
    outline: none
  }

  .slick-list.dragging {
    cursor: hand
  }

  .slick-slider .slick-track,
  .slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0)
  }

  .slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block
  }

  .slick-track:before,
  .slick-track:after {
    display: table;
    content: ''
  }

  .slick-track:after {
    clear: both
  }

  .slick-loading .slick-track {
    visibility: hidden
  }

  .slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px
  }

  .slick-slide.dragging img {
    pointer-events: none
  }

  .slick-initialized .slick-slide {
    display: block
  }

  .slick-loading .slick-slide {
    visibility: hidden
  }

  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
  }

  .arrows {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    direction: ltr;
  }

 

  .arrows-top {
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9;
    direction: ltr;
  }

  .Slick-Next,
  .Slick-Prev {
    display: inline-block;
    width: 38px;
    height: 38px;
    margin-left: 8px;
    cursor: pointer;
    text-align: center;
    transition: all 0.5s;
    border-radius: 16px;
  }


  .Slick-Next {
    background: url(https://img.icons8.com/ios/50/1c5456/long-arrow-right.png) 0 0 / 100% no-repeat;
    right: 3%;
  }

  .Slick-Prev {
    background: url(https://img.icons8.com/ios/50/1c5456/long-arrow-left.png) 0 0 / 100% no-repeat;
    left: 3%;
  }


  .arrows--center {
   

    .Slick-Next, 
    .Slick-Prev {  
      // display: flex;
      // align-items: center;
      // justify-content: space-between;
      // z-index: 9;
      position: absolute;
      bottom: 0;
      top: 0;
      margin: auto;
      margin-left: 0;}
  }

 


  .slick-dots {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-left: 0 !important;
    padding-top: 16px;
  

    button {
      background-color: $c-main;
      border-radius: 50px;
      height: 12px;
      width: 12px;
      font-size: 0;
      border: none;

      @media (max-width: 600px) {
        height: 8px;
        width: 8px;
      }
    }

    li {
      opacity: .5;
      margin: 4px;
    }

    .slick-active {
      opacity: 1;
    }
  }


}


