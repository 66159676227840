.history--area {
    background-color: #EDF4F1;

    &--item {
        cursor: pointer;
        width: 100%;

        &--image {
            img {
                width: 100%;
                height: 330px;
                object-fit: cover; 
            }
        }

        &--content {
            position: relative;
            padding-top: 45px;

            &:after {
                position: absolute;
                width: 100%;
                top: 45px;
                left: 0;
                content: "";
                border-bottom: 1px solid #6A738A;
            }

            .year {
                font-size: 32px;
                line-height: 36px;
                color: $c-dark;
                display: table;
                letter-spacing: -1px;
                padding-right: 10px;
                background-color: #EDF4F1;
                z-index: 1;
                position: relative;
                margin: -18px 0 20px;
                font-weight: 800;
            }

            h3 {
                font-size: clamp(16px, 3vw, 20px);
                font-weight: 600;
                color: $c-main;
                margin: 16px 0;
                line-height: 32px;
            }

            .body {

                overflow-y: auto;
                height: 120px;

                &::-webkit-scrollbar {
                    width: 2px;
                    
                }
                
                &::-webkit-scrollbar-track {
                    background-color: rgba($c-main, .08);
                }
                
                &::-webkit-scrollbar-thumb {
                    background-color: rgba($c-main, .6);
                }

                ul {
                    padding-left: 16px;
                    list-style: circle;
                    
                    li {
                        padding:  4px 0;
                        font-size: clamp(14px, 3vw, 16px);
                        line-height: 24px;
                        color: $c-gray;
                        font-weight: 600;
                    }
                }
                p {
                    font-size: clamp(14px, 3vw, 16px);
                    line-height: 24px;
                    color: $c-gray;
                    font-weight: 600;
                }
            }

            


           
        }
    }

        /* the slides */
        .slick-slide {
            margin-left:16px;
          }
        
          /* the parent */
          .slick-list {
            margin-left:-16px;
          }

}